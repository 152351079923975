import * as React  from 'react';
import { useState } from 'react';
import { Box, Typography, Button, useMediaQuery, ButtonBase } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import BarChartIcon from '@mui/icons-material/BarChart';
import PeopleIcon from '@mui/icons-material/People';
import ProjectImgs from './assets/two-project-snapshot.png';
import ProfitShareGridImg from './assets/med-profit-share-grid.png';
import SetupSplitImg from './assets/med-setup-split.png';
import C3LabsLogo from './assets/cloud3labs-logo-main.png';




const Home = () => {
    // will be string for the header name
    const [selectedItem, setSelectedItem] = useState('');


    const handleClick = (item) => {
        if (selectedItem === item) {
            setSelectedItem('')
        } else {
            setSelectedItem(item)
        }
    }
    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: useMediaQuery('(min-width:900px)') ? 'row' : 'column',
                    justifyContent: useMediaQuery('(min-width:900px)') ? 'center' : null,
                    alignItems: useMediaQuery('(min-width:900px)') ? null : 'center',
                    background: 'rgb(0,29,51)',
                    height: '70vh',
                    background: 'rgb(0,29,51)',
                    background: 'linear-gradient(0deg, rgba(0,29,51,1) 0%, rgba(13,146,137,1) 100%)',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        padding: '21px',
                        color: 'white',
                        width: useMediaQuery('(min-width:900px)') ? '40%' : '80%',
                        margin: 'auto',
                        // height: '30vh',
                        padding: '24px',
                    }}
                >
                    <Box
                        sx={{
                            textAlign: 'center',
                            margin: 'auto',
                            marginTop: '32px',
                        }}
                    >
                        {/* <Typography variant="h1" fontWeight={'bold'} fontSize={useMediaQuery('(min-width:900px)') ? null : '48px'} textAlign="center">Cloud3 Labs</Typography> */}
                        <Box component={'img'} src={C3LabsLogo} width={useMediaQuery('(min-width:900px)') ? "600px" : '280px'} margin={'auto'} />
                        <Typography variant="h2" fontSize={useMediaQuery('(min-width:900px)') ? '40px' : '21px'} fontWeight="thin">Welcome to the user-friendly layer for Web3</Typography>
                        <Button
                            onClick={() => window.open('https://calendly.com/connorcahill/cloud3-labs-chat')}
                            sx={{
                                backgroundColor: '#FF6C01',
                                marginTop: '48px',
                                '&:hover': {
                                    backgroundColor: '#FF6C01',
                                    boxShadow: '3px 4px 15px 4px rgba(255, 108, 1, 0.4)'
                                }
                            }} 
                            variant='contained'
                        >
                            <Typography fontWeight="700" fontSize={'18px'}>Get Started Today</Typography>
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: useMediaQuery('(min-width:900px)') ? 'start' : 'center',
                            width: '80%',
                            margin: 'auto',
                            marginTop: '32px',
                            marginLeft: useMediaQuery('(min-width:900px)') ? '21px' : null,
                        }}
                    >
                    </Box>
                </Box>
            </Box>
            {/* ICON SECTION */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: useMediaQuery('(min-width:900px)') ? 'row' : 'column',
                    alignItems: useMediaQuery('(min-width:900px)') ? null : 'center',
                    color: 'white',
                    width: '100%',
                    paddingTop: '32px',
                }}
            >
                {/* Deploy section */}
                <ButtonBase
                    sx={{
                        width: useMediaQuery('(min-width:900px)') ? '60%' : '80%',
                        textAlign: 'center',
                        backgroundColor: selectedItem === 'DEPLOY' ? 'rgba(13, 146, 137, 0.2)' : null,
                        marginTop: '64px',
                        borderRadius: '12px',
                        '&:hover': {
                            backgroundColor: 'rgba(13, 146, 137, 0.2)',
                            // boxShadow: '3px 4px 15px 4px rgba(13, 146, 137, 0.2)',
                            cursor: 'pointer',
                        }
                    }}
                    onClick={() => handleClick('DEPLOY')}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                           
                        }}
                    >
                        <PlayArrowIcon sx={{
                            fontSize: '88px',
                        }} />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '60%',
                                textAlign: 'center',
                            }}
                        >
                            <Typography
                                fontWeight={'bold'}
                                sx={{ textDecoration: 'underline', marginBottom: '16px' }}
                                variant={useMediaQuery('(min-width:900px)') ? 'h4' : 'h5'}
                            >Deploy{' '}</Typography>
                            <Typography variant={useMediaQuery('(min-width:900px)') ? 'h4' : 'h5'} sx={{ verticalAlign: 'end'}}>Web3 projects with a few clicks</Typography>
                        </Box>
                    </Box>
                </ButtonBase>
                
                {/* Manage section  */}
                <ButtonBase
                    sx={{
                        width: useMediaQuery('(min-width:900px)') ? '60%' : '80%',
                        textAlign: 'center',
                        backgroundColor: selectedItem === 'MANAGE' ? 'rgba(13, 146, 137, 0.2)' : null,
                        borderRadius: '12px',
                        marginTop: '64px',
                        '&:hover': {
                            backgroundColor: 'rgba(13, 146, 137, 0.2)',
                            cursor: 'pointer',
                        }
                    }}
                    onClick={() => handleClick('MANAGE')}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <BarChartIcon sx={{
                            fontSize: '88px',
                        }} />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '60%',
                                textAlign: 'center',
                            }}
                        >
                            <Typography
                                fontWeight={'bold'}
                                sx={{ textDecoration: 'underline', marginBottom: '16px' }}
                                variant={useMediaQuery('(min-width:900px)') ? 'h4' : 'h5'}
                            >Manage{' '}</Typography>
                            <Typography variant={useMediaQuery('(min-width:900px)') ? 'h4' : 'h5'}>all your projects in one place</Typography>
                        </Box>
                    </Box>
                </ButtonBase>
               
                {/* Collaborate section  */}
                <ButtonBase
                    sx={{
                        width: useMediaQuery('(min-width:900px)') ? '60%' : '80%',
                        textAlign: 'center',
                        backgroundColor: selectedItem === 'COLLABORATE' ? 'rgba(13, 146, 137, 0.2)' : null,
                        borderRadius: '12px',
                        marginTop: '64px',
                        '&:hover': {
                            backgroundColor: 'rgba(13, 146, 137, 0.2)',
                            cursor: 'pointer',
                        }
                    }}
                    onClick={() => handleClick('COLLABORATE')}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <PeopleIcon sx={{
                            fontSize: '88px',
                        }} />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography
                                fontWeight={'bold'}
                                sx={{ textDecoration: 'underline', marginBottom: '16px'}}
                                variant={useMediaQuery('(min-width:900px)') ? 'h4' : 'h5'}
                            >Collaborate{' '}</Typography>
                            <Typography variant={useMediaQuery('(min-width:900px)') ? 'h4' : 'h5'}>safer and faster</Typography>
                        </Box>
                    </Box>
                </ButtonBase>
                
            </Box>
            {/* DESCRIPTION SECTION */}
            <Box
                sx={{
                    width: useMediaQuery('(min-width:900px)') ? '60%' : '80%',
                    textAlign: 'center',
                    padding: '21px',
                    margin: 'auto',
                    color: 'white',
                    marginTop: '80px',
                    fontSize: '21px',
                    marginBottom: '250px',
                }}
            >
                {
                    selectedItem === 'DEPLOY'
                        ? (
                            <Typography fontSize='21px'>
                                Provide a seamless and secure Web3 experience to your customers, no code or developer necessary.
                                Rollout Web3 campaigns more easily. Bulk deployments allow you to get started faster and cheaper, and maximise ROI.
                            </Typography>
                        ) : selectedItem === 'MANAGE' 
                        ? (
                            <Typography fontSize='21px'>
                                Using the Cloud3 Labs Dashboard, you can manage all of your Web3 campaigns in one place. Track profits, view collaborators, and track performance to ensure you're not letting anything slip through the cracks.
                            </Typography>
                        ) : selectedItem === 'COLLABORATE'
                        ? (
                            <Typography fontSize='21px'>
                                Working with others has never required less trust. Easily setup profit splits to handle the distribution of funds at the smart contract level. Just enter in what share of the profits each party should get and BOOM, the Cloud3 Labs dashboard will set up a contract to distribute incoming funds on an ongoing basis. All collaborators can withdraw funds directly from the dashboard.
                            </Typography>
                        ) : null
                }
            </Box>
                {/* CTA SECTION 1 */}
            <Typography variant="h3"
                sx={{
                    color: 'white',
                    textAlign: 'center',
                    width: useMediaQuery('(min-width:900px)') ? '70%' : '80%',
                    margin: 'auto',
                }}
                fontSize={useMediaQuery('(min-width:900px)') ? null : '32px'}
            >
                Helping Brands, Businesses, and Creators launch Web3 campaigns with ease
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '24px',
                    color: 'white',
                    paddingTop: '40px',
                    marginBottom: '180px',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography variant={'h4'} fontSize={useMediaQuery('(min-width:900px)') ? null : '24px'} fontWeight="bold" sx={{ textAlign: useMediaQuery('(min-width:900px)') ? null : 'center', marginBottom: '24px', color: '#FF6C01'}}>Ready to see how Cloud3 Labs can help you?</Typography>
                <Button sx={{ backgroundColor: '#FF6C01', '&:hover': {
                                    backgroundColor: '#FF6C01',
                                    boxShadow: '3px 4px 15px 4px rgba(255, 108, 1, 0.4)',
                                }}} variant='contained'
                                onClick={() => window.open('https://calendly.com/connorcahill/cloud3-labs-chat')}
                                ><Typography fontWeight="700" variant="h5" fontSize={useMediaQuery('(min-width:900px)') ? null : '21px'}>Schedule a Call</Typography></Button>
            </Box>
            {/* Project image stuff */}
            <Box
                sx={{
                    width: '80%',
                    margin: 'auto',
                    display: 'flex', 
                    flexDirection: useMediaQuery('(min-width:900px)') ? 'row' : 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '300px',
                }}
            >
                <Box component={'img'} src={ProjectImgs} width={useMediaQuery('(min-width:900px)') ? "360px" : '320px'} />
                <Box
                    sx={{
                        width: useMediaQuery('(min-width:900px)') ? '80%' : '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h3" fontSize={useMediaQuery('(min-width:900px)') ? null : '32px'} sx={{ color: 'white', margin: 'auto', marginTop: '24px', textAlign: useMediaQuery('(min-width:900px)') ? 'left' : 'center'}}>Turning Web3 into actionable projects</Typography>
                    <Button
                        sx={{
                            color: '#FF6C01',
                            display: 'none',
                            '&:hover': {
                                color: '#FF6C01',
                            }
                        }}
                    >Learn more about Cloud3 Projects</Button>
                </Box>
            </Box>
            <Box
                sx={{
                    width: useMediaQuery('(min-width:900px)') ? '80%' : '100%',
                    margin: 'auto',
                    display: 'flex', 
                    flexDirection: useMediaQuery('(min-width:900px)') ? 'row' : 'column-reverse',
                    justifyContent: 'space-around',
                    marginTop: '64px',
                    marginBottom: '364px',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        width: '80%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h3" fontSize={useMediaQuery('(min-width:900px)') ? null : '32px'} sx={{ color: 'white', marginTop: '24px', textAlign: useMediaQuery('(min-width:900px)') ? 'left' : 'center' }}>Collaborate trustlessly</Typography>
                    <Button
                        sx={{
                            color: '#FF6C01',
                            display: 'none',
                            '&:hover': {
                                color: '#FF6C01',
                            }
                        }}
                    >Learn about profit splits</Button>
                </Box>
                <Box component={'img'} src={SetupSplitImg} width={useMediaQuery('(min-width:900px)') ? null : '320px'} />

            </Box>
            <Box
                sx={{
                    width: useMediaQuery('(min-width:900px)') ? '80%' : '100%',
                    margin: 'auto',
                    display: 'flex', 
                    flexDirection: useMediaQuery('(min-width:900px)') ? 'column' : 'column-reverse',
                    justifyContent: 'space-around',
                    marginTop: '80px',
                    marginBottom: '380px',
                    alignItems: 'center',
                    textAlign: 'center',
                }}
            >
                <Box>
                    <Typography variant="h3" fontSize={useMediaQuery('(min-width:900px)') ? null : '32px'} sx={{ color: 'white', marginTop: '24px', textAlign: useMediaQuery('(min-width:900px)') ? 'left' : 'center', marginBottom: '24px', }}>Track and pay out in one
                     place</Typography>
                    <Button sx={{  marginBottom: useMediaQuery('(min-width:900px)') ? '48px' : null, color: '#FF6C01', display: 'none',
                            '&:hover': {
                                color: '#FF6C01',
                            }}}>Learn about Funds Retrieval</Button>
                </Box>
                <Box component={'img'} src={ProfitShareGridImg} width={useMediaQuery('(min-width:900px)') ? null : '320px'} />

            </Box>
            {/* FOOTER SECTION  */}
            <Box
                sx={{
                    background: 'rgba(255, 108, 1, 0.8)',
                    color: 'white',
                    borderRadius: '16px 16px 0 0',
                    padding: '32px',
                    marginTop: '80px',
                }}
            >
                <Typography variant="h4" textAlign={'center'}>Subscribe for monthly product updates!</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: useMediaQuery('(min-width:900px)') ? 'row' : 'column',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        width: '40%',
                        margin: 'auto',
                        marginTop: '48px',
                        fontSize: '40px',
                    }}
                >
                    <Button 
                        size='large'
                            sx={{
                                backgroundColor: '#0D9289',
                                marginTop: '24px',
                                marginBottom: '32px',
                                '&:hover': {
                                    backgroundColor: '#0D9289',
                                    boxShadow: '3px 4px 15px 4px rgba(13, 146, 137, 0.5)',
                                }
                            }} 
                            variant='contained'
                            onClick={() => window.open('https://forms.gle/FghTS8aTudsSQc2U8')}
                        >
                            Subscribe
                        </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default Home;