import './App.css';
import Home from './Home';
import { Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundColor: '#001D33',
        }}
      >
        <Home />
      </Box>
    </ThemeProvider>
  );
}

export default App;
