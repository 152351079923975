import { createTheme } from '@mui/material/styles';
import './App.css';

const theme = createTheme({
    typography: {
        fontFamily: "'Nunito Sans', sans-serif",
    }
})


export default theme;